import { getUrlParams } from './urlParams';

export const personalizationIsNoMenu = (pathname: string) => {
  if (!pathname.includes('/maquina-de-cartao')) return false;

  const queryParams = getUrlParams();

  const isPersonalizationNoMenu = queryParams.some(params => {
    return params.name === 'utm_str' && params.value === 'no_menu';
  });

  return isPersonalizationNoMenu;
};
